.topbar {
  padding: 10px 0;

  background-color: $topbar-background-color;

  .topbar-wrapper {
    display: flex;
    align-items: center;
  }

  a {
    font-size: 1.5em;
    font-weight: bold;

    display: flex;
    align-items: center;
    flex: 1;

    max-width: 300px;

    text-decoration: none;

    @include text_headline($topbar-link-font-color);

    span {
      margin: 0;
      padding: 0 10px;
    }
  }

  .download-url-wrapper {
    display: flex;
    flex: 3;
    justify-content: flex-end;

    input[type=text] {
      width: 100%;
      margin: 0;
      border: 2px solid $topbar-download-url-button-background-color;
      border-right: none;
      border-radius: 20px 0 0 20px;
      outline: none;
    }

    input[type=text]:hover {

      border: 2px solid #1666DE;
      border-right: none;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
    input[type=text]:focus{
      border: 2px solid #1666DE;
      border-right: none;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
    }
    .select-label {
      display: flex;
      align-items: center;

      width: 100%;
      max-width: 600px;
      margin: 0;
      color: #f0f0f0;

      span {
        font-size: 16px;

        flex: 1;

        padding: 0 10px 0 0;

        text-align: right;
      }

      select {
        flex: 2;

        width: 100%;

        border: 2px solid $topbar-download-url-wrapper-element-border-color;
        outline: none;
        box-shadow: none;
      }
    }


    .download-url-button {
      font-size: 16px;
      font-weight: bold;
      padding: 4px 30px;
      color: white;
      border-radius: 0 20px 20px 0;
      background-color: $topbar-download-url-button-background-color;
      border: 2px solid $topbar-download-url-button-background-color;
      border-left: none;
    }

    .download-url-button:hover {
      background-color: #1666DE;
      border: 2px solid #1666DE;
      border-left: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    }

    .download-url-button:active {
      transform: scale(1.03);
    }
  }
}
