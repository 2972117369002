.wrapper {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.opblock-tag-section {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.opblock-tag {
  display: flex;
  align-items: center;

  padding: 20px;

  cursor: pointer;
  transition: all .2s;

  &:hover {
    background: rgba($opblock-tag-background-color-hover, .02);
  }
}

@mixin method($color) {
  border-color: $color;
  background: rgba($color, .1);

  .opblock-summary-method {
    background: $color;
  }

  .opblock-summary {
    border-color: $color;
  }

  .tab-header .tab-item.active h4 span:after {
    background: $color;
  }
}


.opblock-tag {
  font-size: 24px;

  margin: 0;

  @include text_headline();

  &.no-desc {
    span {
      flex: 1;
    }
  }

  svg {
    transition: all .4s;
  }

  small {
    font-size: 14px;
    font-weight: normal;

    flex: 1;

    padding: 0 10px;

    @include text_body();
  }
}

.parameter__type {
  font-size: 12px;

  padding: 5px 0;

  @include text_code();
}

.parameter-controls {
  margin-top: 0.75em;
}

.examples {
  &__title {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 0.75em;
  }

  &__section {
    margin-top: 1.5em;
  }

  &__section-header {
    font-weight: bold;
    font-size: .9rem;
    margin-bottom: .5rem;
    // color: #555;
  }
}

.examples-select {
  margin-bottom: .75em;

  &__section-label {
    font-weight: bold;
    font-size: .9rem;
    margin-right: .5rem;
  }
}

.example {
  &__section {
    margin-top: 1.5em;
  }

  &__section-header {
    font-weight: bold;
    font-size: .9rem;
    margin-bottom: .5rem;
    // color: #555;
  }
}

.view-line-link {
  position: relative;
  top: 3px;

  width: 20px;
  margin: 0 5px;

  cursor: pointer;
  transition: all .5s;
}


.opblock {
  margin: 0 32px 20px;

  border: 1px solid $opblock-border-color;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  .tab-header {
    display: flex;

    flex: 1;

    .tab-item {
      padding: 0 40px;

      cursor: pointer;

      &:first-of-type {
        padding: 0 40px 0 0;
      }

      &.active {
        h4 {
          span {
            position: relative;


            &:after {
              position: absolute;
              bottom: -15px;
              left: 50%;

              width: 120%;
              height: 4px;

              content: '';
              transform: translateX(-50%);

              background: $opblock-tab-header-tab-item-active-h4-span-after-background-color;
            }
          }
        }
      }
    }
  }


  &.is-open {
    .opblock-summary {
      border-bottom: 1px solid $opblock-isopen-summary-border-bottom-color;
    }
  }

  .opblock-section-header {
    display: flex;
    align-items: center;

    padding: 8px 20px;

    min-height: 50px;

    background: rgba($opblock-isopen-section-header-background-color, .8);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

    > label {
      font-size: 12px;
      font-weight: bold;

      display: flex;
      align-items: center;

      margin: 0;
      margin-left: auto;

      @include text_headline();

      > span {
        padding: 0 10px 0 0;
      }
    }

    h4 {
      font-size: 14px;

      flex: 1;

      margin: 0;

      @include text_headline();
    }
  }

  .opblock-summary-method {
    font-size: 14px;
    font-weight: bold;

    min-width: 80px;
    padding: 6px 15px;

    text-align: center;

    border-radius: 45px;
    background: $opblock-summary-method-background-color;
    text-shadow: 0 1px 0 rgba($opblock-summary-method-text-shadow-color, .1);

    @include text_headline($opblock-summary-method-font-color);
  }

  .opblock-summary-path,
  .opblock-summary-operation-id,
  .opblock-summary-path__deprecated {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }


    display: flex;
    align-items: center;

    word-break: break-word;

    padding: 0 10px;

    @include text_code();

  }

  .opblock-summary-path__deprecated {
    text-decoration: line-through;
  }

  .opblock-summary-operation-id {
    font-size: 14px;
  }

  .opblock-summary-description {
    font-size: 13px;

    flex: 1 1 auto;

    word-break: break-word;

    @include text_body();
  }

  .opblock-summary {
    display: flex;
    align-items: center;

    padding: 16px 8px;

    cursor: pointer;

    .view-line-link {
      position: relative;
      top: 2px;

      width: 0;
      margin: 0;

      cursor: pointer;
      transition: all .5s;
    }

    &:hover {
      .view-line-link {
        width: 18px;
        margin: 0 5px;
      }
    }
  }


  &.opblock-post {
    @include method($_color-post);
  }

  &.opblock-put {
    @include method($_color-put);
  }

  &.opblock-delete {
    @include method($_color-delete);
  }

  &.opblock-get {
    @include method($_color-get);
  }

  &.opblock-patch {
    @include method($_color-patch);
  }

  &.opblock-head {
    @include method($_color-head);
  }

  &.opblock-options {
    @include method($_color-options);
  }

  &.opblock-deprecated {
    opacity: .6;

    @include method($_color-disabled);
  }

  .opblock-schemes {
    padding: 8px 20px;

    .schemes-title {
      padding: 0 10px 0 0;
    }
  }
}

.filter {
  .operation-filter-input {
    width: 100%;
    margin: 20px 0;
    padding: 10px 10px;

    border: 2px solid $operational-filter-input-border-color;
  }
}

.model-example {
  margin-top: 1em;
}

.tab {
  display: flex;

  padding: 0;

  list-style: none;

  li {
    font-size: 12px;

    min-width: 60px;
    padding: 0;

    cursor: pointer;

    @include text_headline();

    &:first-of-type {
      position: relative;

      padding-left: 0;
      padding-right: 12px;

      &:after {
        position: absolute;
        top: 0;
        right: 6px;

        width: 1px;
        height: 100%;

        content: '';

        background: rgba($tab-list-item-first-background-color, .2);
      }
    }

    &.active {
      font-weight: bold;
    }
  }
}

.opblock-description-wrapper,
.opblock-external-docs-wrapper,
.opblock-title_normal {
  font-size: 12px;

  margin: 0 0 5px 0;
  padding: 15px 20px;

  @include text_body();

  h4 {
    font-size: 12px;

    margin: 0 0 5px 0;

    @include text_body();
  }

  p {
    font-size: 14px;

    margin: 0;

    @include text_body();
  }
}

.opblock-external-docs-wrapper {
  h4 {
    padding-left: 0px;
  }
}

.execute-wrapper {
  padding: 20px;
  display: flex;
  text-align: center;

  .btn {
    padding: 8px 40px;
  }
}

.body-param-options {
  display: flex;
  flex-direction: column;

  .body-param-edit {
    padding: 10px 0;
  }

  label {
    padding: 8px 0;

    select {
      margin: 3px 0 0 0;
    }
  }
}

.responses-inner {
  padding: 20px;

  h5,
  h4 {
    font-size: 12px;

    margin: 10px 0 5px 0;

    @include text_body();
  }
}

.response-col_status {
  font-size: 14px;

  @include text_body();

  .response-undocumented {
    font-size: 11px;

    @include text_code($response-col-status-undocumented-font-color);
  }
}

.response-col_links {
  padding-left: 2em;
  max-width: 40em;
  font-size: 14px;

  @include text_body();

  .response-undocumented {
    font-size: 11px;

    @include text_code($response-col-links-font-color);
  }
}

.opblock-body {
  .opblock-loading-animation {
    display: block;
    margin: 3em;
    margin-left: auto;
    margin-right: auto;
  }
}

.opblock-body pre {
  font-size: 12px;

  margin: 0;
  padding: 10px;

  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;

  border-radius: 8px;
  background: rgba(0,0,0,.03);

  overflow-wrap: break-word;
  color: #3d4261;
  span {
    color: #3d4261;
  }

  .headerline {
    display: block;
  }
}

.highlight-code {
  position: relative;

  > .microlight {
    overflow-y: auto;
    max-height: 400px;
    min-height: 6em;
    font-weight: 600;
  }
}

.download-contents {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  background: #7d8293;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-family: sans-serif;
  font-weight: 600;
  color: white;
  font-size: 14px;
  height: 30px;
  width: 75px;
}

.scheme-container {
  padding: 24px 0;

  .schemes {
    display: flex;
    align-items: center;

    > label {
      font-size: 12px;
      font-weight: bold;

      display: flex;
      flex-direction: column;

      margin: -20px 15px 0 0;

      @include text_headline();

      select {
        min-width: 130px;

        text-transform: uppercase;
      }
    }
  }
}

.loading-container {
  padding: 40px 0 60px;
  margin-top: 1em;
  min-height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;

      display: block;

      width: 60px;
      height: 60px;
      margin: -30px -30px;

      content: '';
      animation: rotation 1s infinite linear, opacity .5s;

      opacity: 1;
      border: 2px solid #fff;
      border-top-color: #0056d6;
      border-radius: 100%;

      backface-visibility: hidden;

      @keyframes rotation {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.response-controls {
  padding-top: 1em;
  display: flex;
}

.response-control-media-type {
  margin-right: 1em;

  &--accept-controller {
    select {
      border-color: $response-content-type-controls-accept-header-select-border-color;
    }
  }

  &__accept-message {
    color: $response-content-type-controls-accept-header-small-font-color;
    font-size: .7em;
  }

  &__title {
    display: block;
    margin-bottom: 0.2em;
    font-size: .7em;
  }
}

.response-control-examples {
  &__title {
    display: block;
    margin-bottom: 0.2em;
    font-size: .7em;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


section {
  h3 {
    @include text_headline();
  }
}

a.nostyle {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
  display: inline;

  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
}

.version-pragma {
  height: 100%;
  padding: 5em 0px;

  &__message {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 1.2em;
    text-align: center;
    line-height: 1.5em;

    padding: 0px .6em;

    > div {
      max-width: 55ch;
      flex: 1;
    }

    code {
      background-color: #dedede;
      padding: 4px 4px 2px;
      white-space: pre;
    }
  }
}
