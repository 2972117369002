.btn {
  font-size: 14px;
  font-weight: bold;

  padding: 5px 23px;

  transition: all .3s;

  border: 2px solid $btn-border-color;
  border-radius: 45px;
  background: #fff;

  @include text_headline();

  &:hover {
    background-color: $btn-border-color;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  &:active {
    transform: scale(0.97);
  }

  &.btn-sm {
    font-size: 12px;
    padding: 8px 23px;
  }

  &[disabled] {
    cursor: not-allowed;

    opacity: .3;
  }

  &:hover {
    box-shadow: 0 0 5px rgba($btn-box-shadow-color, .3);
  }

  &.cancel {
    border-color: $btn-cancel-border-color;
    background-color: $btn-cancel-background-color;
    @include text_headline($btn-cancel-font-color);
    &:hover {
      background-color: $btn-cancel-border-color;;
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    &:active {
      transform: scale(0.97);
    }
  }

  &.authorize {
    line-height: 1;

    display: inline;
    transition: all 200ms;
    color: $btn-authorize-font-color;
    border-color: $btn-authorize-border-color;
    background-color: $btn-authorize-background-color;

    span {
      float: left;

      padding: 4px 20px 0 0;
    }

    svg {
      fill: $btn-authorize-svg-fill-color;
      transition: all 200ms;
    }

    &:hover {
      background-color: $btn-authorize-border-color;
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    &:active {
      transform: scale(0.97);
    }
  }

  &.execute {
    background-color: $btn-execute-background-color-alt;
    color: $btn-execute-font-color;
    border-color: $btn-execute-border-color;
  }
  &.execute:hover {
    background-color: #145dd6;
    border-color: #145dd6;
  ;
  }
}

.btn-group {
  display: flex;

  padding: 30px;
  .btn {
    padding: 8px 40px;
    &:first-child{
      margin-right: 8px;
    }
  }
}

.authorization__btn {
  padding: 0 10px;
  transition: all 200ms;
  border: none;
  background: none;

  &.locked {
    opacity: 1;
  }

  &.unlocked {
    opacity: .4;
  }

  &:hover svg {
    opacity: 0.7;
  }

  &:active svg {
    transform: scale(0.93);
  }
}

.expand-methods,
.expand-operation {
  border: none;
  background: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

.expand-methods {
  padding: 0 10px;

  &:hover {
    svg {
      fill: $expand-methods-svg-fill-color-hover;
    }
  }

  svg {
    transition: all .3s;

    fill: $expand-methods-svg-fill-color;
  }
}


button {
  cursor: pointer;
  outline: none;

  &.invalid {
    @include invalidFormElement();
  }
}
