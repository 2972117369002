.dialog-ux {
  @import '~tachyons-sass/tachyons.scss';
  @import 'mixins';
  @import 'variables';
  @import 'type';
  @import 'layout';
  @import 'buttons';
  @import 'form';
  @import 'models';
  @import 'servers';
  @import 'table';
  @import 'topbar';
  @import 'information';
  @import 'authorize';
  @import 'errors';
  @include text_body();
  @import 'split-pane-mode';
  @import 'markdown';
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .backdrop-ux {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgba($dialog-ux-backdrop-background-color, .8);
  }

  .modal-ux {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;

    width: 100%;
    min-width: 300px;
    max-width: 650px;

    transform: translate(-50%, -50%);

    border: 1px solid $dialog-ux-modal-border-color;
    border-radius: 16px;
    background: $dialog-ux-modal-background-color;
    box-shadow: 0 10px 30px 0 rgba($dialog-ux-modal-box-shadow-color, .20);
  }

  .modal-ux-content {
    max-height: 540px;
    padding: 12px;

    p {
      font-size: 14px;

      margin: 0 0 8px 0;

      color: $dialog-ux-modal-content-font-color;

      @include text_body();
    }

    h4 {
      font-size: 18px;
      font-weight: 600;

      margin: 15px 0 0 0;

      @include text_headline();
    }
  }

  .modal-ux-header {
    display: flex;

    padding: 12px 0;

    border-bottom: 1px solid $dialog-ux-modal-header-border-bottom-color;

    align-items: center;

    .close-modal {
      padding: 0 10px;

      border: none;
      background: none;

      appearance: none;
    }


    h3 {
      font-size: 20px;
      font-weight: 600;

      margin: 0;
      padding: 0 20px;

      flex: 1;
      @include text_headline();
    }
  }
}
